/**
 * @file It contains scripts for channel overview page
 */
import { mapGetters, mapActions }                 from "vuex"
import { getListOfStrings, format, downloadFile } from "@/utils"
import QRCode                                     from "qrcode"

export default {
  name: "Channels",
  data() {
    return {
      filters: undefined,
      search : undefined
    }
  },
  methods: {
    ...mapActions({
      downloadChannelsOverview: "channels/downloadChannelsOverview"
    }),
    /**
     * This method is used custom filtering for vuetify data table.
     * This will be called for every cell value in the table.
     * @param {*} value is content of each cell in a the table
     */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value && value.toString().includes(element)) + 1
    },
    async qrCodeDownload(channel) {
      const qrCodeUrl = await QRCode.toDataURL([{
        data: `${format(process.env.VUE_APP_REPORTER_URL, this.clientName, channel.channelName)}?organisation-code=${channel.channelOrganisationCode}`
      }])
      downloadFile(qrCodeUrl, undefined, `${channel.channelName}.png`)
    }
  },
  computed: {
    ...mapGetters({
      channels                     : "channels/channels",
      isLoadingChannels            : "channels/isLoadingChannels",
      clientName                   : "auth/clientName",
      domains                      : "domains/domains",
      isDownloadingChannelsOverview: "channels/isDownloadingChannelsOverview"
    }),
    domainsMap() {
      const domainsMap = new Object()
      for (const domain of this.domains) {
        domainsMap[domain.id] = domain
      }
      return domainsMap
    },
    channelsForTable() {
      return this.channels.map(channel => {
        return {
          id                     : channel.id,
          channelName            : channel.name,
          domain                 : this.domainsMap[channel.domainId]?.name,
          channelUrl             : format(process.env.VUE_APP_REPORTER_URL, this.clientName, channel.name),
          channelOrganisationCode: channel.organisationCode,
          channelStatus          : channel.enabled ? this.$t("163") : this.$t("164")
        }
      })
    },

    headersForTable() {
      return this.$TABLES.CHANNELS.headers.map(header => {
        return {
          ...header, ...{
            text: this.$t(header.text)
          }
        }
      })
    },
    footersForTable() {
      return {
        ...this.$TABLES.CHANNELS.footer, ...{
          itemsPerPageText: this.$t(this.$TABLES.CHANNELS.footer.itemsPerPageText)
        }
      }
    },
    itemsForSearch() {
      return getListOfStrings(this.channelsForTable, ["id"])
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    }
  }
}
